html,
body {
  height: 100%;
  width: 100%;
  max-width: 100%;
  padding-top: 15px;
}

body {
  @include sans-serif-font;
}

hr {
  border-color: $theme-dark;
  border-width: 3px;
  max-width: 50px;
}

hr.light {
  border-color: white;
}

hr.primary {
  border-color: $theme-primary;
}

a {
  @include transition-all;
  color: $theme-primary;
  &:hover,
  &:focus {
    color: darken($theme-primary, 10%);
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @include sans-serif-font;
  color: $theme-dark;
}

p {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 20px;
  padding: 0px 20px 0px 20px;
}

.bg-primary {
  background-color: $theme-primary;
}

.bg-dark {
  background-color: $theme-dark;
  color: white;
}

.bg-light {
  background-color: lighten($color: $theme-primary, $amount: 25);
}

.text-primary {
  color: $theme-primary;
}

.text-white {
  color: white;
}

.text-faded {
  color: rgba(white, 0.7);
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

section {
  padding: 100px 0;
}

aside {
  padding: 50px 0;
}

.no-padding {
  padding: 0;
}

.content {
  overflow-x: hidden;
}

.buttons-container {
  > a {
    margin: 8px;
  }
}

// Navigation

.navbar-default {
  background-color: white;
  border-bottom-width: 2px;
  border-color: $theme-dark;
  // @include sans-serif-font;
  // @include transition-all;
  .navbar-header .navbar-brand {
    color: $theme-primary;
    background-color: white;
    // @include sans-serif-font;
    font-weight: 700;
    text-transform: uppercase;
    &:hover,
    &:focus {
      color: darken($theme-primary, 10%);
    }
  }
  .navbar-toggle {
    border-color: $theme-primary;
    .icon-bar {
      background-color: $theme-primary;
    }
    &:hover {
      background-color: rgba($theme-primary, 0.25);
    }
  }
  .nav {
    > a.btn {
      margin: 10px;
      width: "100%";
    }
    > li {
      > a.nav-link,
      > a.nav-link:focus {
        text-transform: uppercase;
        height: 50px;
        line-height: 50px;
        padding-top: 0;
        padding-bottom: 0;
        font-weight: 700;
        font-size: 13px;
        color: $theme-dark;
        &:hover {
          color: $theme-primary;
        }
      }
      &.active {
        > a.nav-link,
        > a.nav-link:focus {
          color: $theme-primary !important;
          background-color: transparent;
          &:hover {
            background-color: transparent;
          }
        }
      }
    }
  }
  @media (min-width: 992px) {
    padding: 0px 150px;
  }
}

// Homepage Header

header {
  @include background-cover;
  background-position: center;
  background-image: url("../img/header.jpg");
  color: $theme-dark;
  .fixed {
    position: fixed;
    width: 100%;
    max-width: 100%;
    z-index: 1;
    left: 0;
    right: 0;
    margin-left: 0;
    margin-right: 0;
  }
  .header {
    height: 80px;
    padding: 20px 0px;
    background-color: rgba($theme-dark, 0.5);
    font-size: 14px;
    span {
      color: white;
      text-decoration: none;
      line-height: 60px;
    }
  }
  .padding {
    padding-top: 80px;
  }
  .box {
    padding: 50px 0px 50px 0px;
    background-color: rgba(white, 0.9);
    border-radius: 20px;
    overflow: auto;
    .left-side {
      text-align: center;
      .title {
        font-size: 20px;
      }
      img {
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 50px;
        width: 50%;
      }  
    }
    .carousel {
      border-radius: 10px !important;
      overflow: hidden !important;
      margin: 10px;
      img {
        width: 100%;
        height: 80% !important;
        object-fit: cover;
      }
    }
  }
  @media (min-width: 768px) {
    .header {
      font-size: 24px;
    }x
    .box {
      .left-side {
        .title {
          font-size: 30px;
        }
        img {
          width: 25%;
        }  
      }
    }
  }
  @media (min-width: 992px) {
    .box {
      margin: 50px 100px 50px 100px;
    }
  }
}

// Sections

.section-heading {
  margin-top: 0;
}

footer {
  height: 50px;
  background-color: $theme-dark;
  color: white;
  text-align: center;
  padding: 15px 0px 15px;
}

// Bootstrap Overrides
.text-primary {
  color: $theme-primary;
}

.text-dark {
  color: $theme-dark;
}

.no-gutter > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

// Button Styles
.btn-default {
  @include button-variant(white, $theme-dark, $theme-dark);
}

.btn-primary {
  @include button-variant(white, $theme-primary, $theme-primary);
}

.btn-outline {
  @include button-variant($theme-dark, white, $theme-dark);
}

.btn {
  @include sans-serif-font;
  border-radius: 300px;
  font-weight: 700;
  text-transform: uppercase;
}

.btn-sm {
  padding: 5px 15px;
  font-weight: 500;
}

.btn-xl {
  padding: 15px 30px;
}

// About Us
#about {
  img {
    width: 50%;
    height: 50%;
    border: 2px solid white;
    border-radius: 20px;
  }
  @media (min-width: 768px) {
    img {
      width: 100%;
      height: 100%;
    }
  }
}

// Contact
#contact {
  margin-top: 20px;
  p {
    padding: 0;
  }
  h4 {
    text-transform: uppercase;
    margin-top: 20px;
  }
  td {
    padding: 2px 8px 2px 0px;
  }
  .map {
    padding: 20px;
  }
  .mapouter {
    position: relative;
    text-align: right;
    width: 100%;
    padding-top: 100%;
  }
  .gmap_canvas {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    background: none !important;
  }
}

// Extras
// -- Highlight Color Customization
::-moz-selection {
  color: white;
  text-shadow: none;
  background: $theme-dark;
}

::selection {
  color: white;
  text-shadow: none;
  background: $theme-dark;
}

img::selection {
  color: white;
  background: transparent;
}

img::-moz-selection {
  color: white;
  background: transparent;
}

body {
  -webkit-tap-highlight-color: $theme-dark;
}
